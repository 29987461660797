import React from 'react';
import ReactImageMagnify from 'react-image-magnify';
import useViewports from '../../../../../../Hooks/useViewports';
import { IProductListProduct } from '../../../../ProductList';
import { Image } from 'react-bootstrap';
//@ts-ignore
import Styles from './ZoomImage.module.scss?module';

type ZoomImagePropsT = {
  src: IProductListProduct['image_uri'];
  alt?: string;
  containerW?: string;
  containerH?: string;
};

const ZoomImage = ({
  src,
  alt = 'product image',
  containerW = '330%',
  containerH = '250%',
}: ZoomImagePropsT) => {
  const { xs, sm, md } = useViewports();

  return (
    <div className={Styles.ImageContainer}>
      {xs || sm || md ? (
        <Image fluid src={src} className="h-auto" />
      ) : (
        <ReactImageMagnify
          {...{
            smallImage: {
              alt,
              isFluidWidth: true,
              src,
            },
            largeImage: {
              src,
              width: 1000,
              height: 1500,
            },
            enlargedImageContainerDimensions: {
              width: containerW,
              height: containerH,
            },
            enlargedImageContainerClassName: Styles.ZoomContainer,
          }}
        />
      )}
    </div>
  );
};

export default ZoomImage;
